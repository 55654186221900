import { useQuery } from '@apollo/client';
import { Container } from './ChatOnboarding.style';
import { meQueryDocument } from '../../../Layout/useLayout';
import CustomTypography from '../../../common/CustomTypography/CustomTypography';
import { useIntl } from 'react-intl';

interface props {
  todoId: number;
}

const ChatOnboarding = ({ todoId }: props) => {
  const { data: user } = useQuery(meQueryDocument);
  const intl = useIntl();

  return (
    <Container>
      <img src="/swiftask.png" alt="ai-onboarding" width="90" />

      <CustomTypography variant="h1" fontWeight={'bold'}>
        {intl.formatMessage({ id: 'chat.onboarding.welcome.title' })}{' '}
        <b>{user?.me.lastName}</b>
      </CustomTypography>
    </Container>
  );
};

export default ChatOnboarding;
