import { FC, useState } from 'react';
import useSTGDrive from './useSTGdrive';
import { FileType } from '../../../Hooks/useCreateFile';
import CustomBox from '../CustomBox/CustomBox';
import CustomButton from '../CustomButton/CustomButton';
import FileList from '../STFileForm/FileList';
import useExternalAppIntegration from '../../../Hooks/externalApp/useExternalAppIntegration';

interface IProps {
  onUpload?(files: FileType[]): void;
}

const STGoogleDrive: FC<IProps> = ({ onUpload }) => {
  const [files, setFiles] = useState<FileType[]>([]);

  const { handleOpenPicker } = useSTGDrive({
    onUpload: (files) => {
      setFiles(files);
      if (onUpload) {
        onUpload(files);
      }
    },
  });
  return (
    <CustomBox>
      <CustomBox>
        <CustomButton variant="contained" onClick={() => handleOpenPicker()}>
          Open Picker
        </CustomButton>
      </CustomBox>
      <CustomBox>
        <FileList
          files={files}
          onDelete={(index) => {
            const newFiles = files.filter((_, i) => i !== index);
            setFiles(newFiles);
            if (onUpload) {
              onUpload(newFiles);
            }
          }}
        />
      </CustomBox>
    </CustomBox>
  );
};

export default STGoogleDrive;
