import { FC, useState } from 'react';
import { useEffect } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import useUploadGdriveFile from './useUploadGdriveFile';
import { FileType } from '../../../Hooks/useCreateFile';
import { NewGdriveInput } from '../../../gql/graphql';
import useExternalAppIntegration from '../../../Hooks/externalApp/useExternalAppIntegration';

interface IProps {
  onUpload?(files: FileType[]): void;
}

const useSTGDrive = ({ onUpload }: IProps = {}) => {
  const [openPicker, authResponse] = useDrivePicker();

  const { uploadGDriveFiles, loading, error } = useUploadGdriveFile({
    onCompleted: (files) => {
      if (onUpload) {
        onUpload(files);
      }
    },
  });

  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  // const customViewsArray = [new google.picker.DocsView()]; // custom view

  const { connectExternalApp } = useExternalAppIntegration({
    onAuthSuccess: (accessToken) => {
      onAuthenticated(accessToken);
    },
  });
  const onAuthenticated = (gDriveAccessToken: string) => {
    openPicker({
      clientId:
        '384544193935-8n6qbpvh679p5qsck5h0kenstaand6r1.apps.googleusercontent.com',
      developerKey: 'AIzaSyCU-GzWM-MO1cP9XwnBkJHqLWDarvN_GpI',
      viewId: 'DOCS',
      token: gDriveAccessToken, // pass oauth token in case you already have one
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: true,
      multiselect: true,
      // customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
          console.log('User clicked cancel/close button');
        }

        const token = authResponse?.access_token;

        if (data.action === 'picked' && data.docs) {
          const files = data.docs;
          console.log(files);
          const filesToUpload: NewGdriveInput[] = files.map((file) => {
            return {
              name: file.name,
              gdriveFileId: file.id,
              grdriveUrl: file.url,
              mimeType: file.mimeType,
            };
          });
          uploadGDriveFiles({
            variables: {
              files: filesToUpload,
              accessToken: gDriveAccessToken,
            },
          });
        }
      },
    });
  };
  const handleOpenPicker = () => {
    connectExternalApp('google-drive');
  };
  return {
    handleOpenPicker,
    loading,
    error,
  };
};

export default useSTGDrive;
