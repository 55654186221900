// Custom popper component using material-ui Popper component

import { FC } from 'react';

import { Popper, PopperProps } from '@mui/material';

interface IProps extends PopperProps {}

const STPopper: FC<IProps> = ({ children, ...rest }) => {
  return <Popper {...rest}>{children}</Popper>;
};

export default STPopper;
