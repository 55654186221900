import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import DataSourceIndexStatus from '../../DataSourcesPage/DataSourceIndexStatus/DataSourceIndexStatus';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
import useGetWorkspaceDataSources from '../../../Hooks/useGetWorkspaceDataSources';
import CustomBox from '../CustomBox/CustomBox';
import { chain, get, map, uniq } from 'lodash';
import { ChatDataSourceSyncStatus } from '../../../gql/graphql';

interface UseDataSourcePickerProps {
  onCheckRow?: (selectionModel: GridRowSelectionModel, details: any) => void;
  value: number[];
  readonly?: boolean;
}

const useDataSourcePicker = ({
  onCheckRow,
  value,
  readonly,
}: UseDataSourcePickerProps) => {
  const intl = useIntl();
  const { data } = useGetWorkspaceDataSources();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedDataSources, setSelectedDataSources] = useState<any[]>([]);
  const rows = useMemo(() => {
    if (!data?.getWorkSpaceDataSources) return [];

    return chain(data.getWorkSpaceDataSources)
      .filter({ syncStatus: ChatDataSourceSyncStatus.Success })
      .map((value) => ({
        id: value.id,
        name: value.name,
        syncStatus: value.syncStatus,
        category: get(value, 'dataSourceCategory.name', ''),
        syncToVectorStoreError: value.syncToVectorStoreError,
        createdAt: moment(value.createdAt).format('D MMM YYYY'),
      }))
      .value();
  }, [data]);

  const categories = uniq(map(rows, 'category'));

  useEffect(() => {
    if (value && value.length > 0) {
      const selectedRows = rows.filter((row) => value.includes(row.id));
      setSelectedDataSources(selectedRows);
    }
  }, [rows, value]);

  const handleCheckRow = useCallback(
    (selectionModel: GridRowSelectionModel, details: any) => {
      onCheckRow && onCheckRow(selectionModel, details);
      const selectedRows = rows.filter((row) =>
        selectionModel.includes(row.id)
      );
      setSelectedDataSources(selectedRows);
    },
    [onCheckRow, rows]
  );

  const handleDeleteRow = useCallback(
    (id: number) => {
      const newSelectionModel = (value || []).filter((rowId) => rowId !== id);
      onCheckRow && onCheckRow(newSelectionModel, {});
      setSelectedDataSources((prev) =>
        prev.filter((source) => source.id !== id)
      );
    },
    [onCheckRow, value]
  );

  const columns: GridColDef<any>[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 100 },
      {
        field: 'name',
        headerName: intl.formatMessage({ id: 'table.datasource.name' }),
        width: 250,
      },
      {
        field: 'category',
        headerName: intl.formatMessage({ id: 'table.datasource.category' }),
        width: 250,
      },
      {
        field: 'status',
        headerName: intl.formatMessage({ id: 'table.datasource.index.status' }),
        width: 200,
        renderCell: (params) => (
          <CustomBox pt={1}>
            <DataSourceIndexStatus
              syncToVectorStoreError={params.row.syncToVectorStoreError}
              syncStatus={params.row.syncStatus}
            />
          </CustomBox>
        ),
      },
      {
        field: 'createdAt',
        headerName: intl.formatMessage({
          id: 'table.datasource.date.of.addition',
        }),
        width: 200,
      },
    ],
    [intl]
  );

  const columnsSelected: GridColDef<any>[] = useMemo(
    () => [
      ...columns.slice(0, -1),
      ...(!readonly
        ? [
            {
              field: 'action',
              headerName: 'Action',
              width: 100,
              renderCell: (params: any) => (
                <CustomIconButton
                  onClick={() => handleDeleteRow(params.row.id)}
                >
                  <DeleteOutlineIcon />
                </CustomIconButton>
              ),
            },
          ]
        : []),
    ],
    [columns, handleDeleteRow]
  );

  return {
    columns,
    rows,
    setOpen,
    open,
    handleCheckRow,
    rowsSelected: selectedDataSources ?? [],
    columnsSelected,
    categories,
  };
};

export default useDataSourcePicker;
