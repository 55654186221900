import { FC } from 'react';
import Add from '@mui/icons-material/Add';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import STDataGrid from '../STDataGrid/STDataGrid';
import useDataSourcePicker from './useDataSourcePicker';
import CustomBox from '../CustomBox/CustomBox';
import CustomButton from '../CustomButton/CustomButton';
import CustomDialog from '../CustomDialog/CustomDialog';
import { useIntl } from 'react-intl';
import AlertText from '../AlertText/AlertText';
import useDataSourcesPageFilter from '../../DataSourcesPage/hooks/useDataSourcesPageFilter';
import DatasourceSearchBarWithFilter from '../../DataSourcesPage/DatasourceHeader/DatasourceSearchBarWithFilter/DatasourceSearchBarWithFilter';
import { useTheme } from '@mui/material';

interface IProps {
  value: number[];
  onCheckRow?: (selectionModel: GridRowSelectionModel, details: any) => void;
  readonly?: boolean;
}

const STDataSourcePicker: FC<IProps> = ({ value, onCheckRow, readonly }) => {
  const {
    columns,
    rows,
    setOpen,
    open,
    handleCheckRow,
    rowsSelected,
    columnsSelected,
    categories,
  } = useDataSourcePicker({
    onCheckRow,
    value,
    readonly,
  });
  const {
    filteredRows,
    syncStatusList,
    setQueryString,
    setCurrentCategory,
    setCurrentStatus,
  } = useDataSourcesPageFilter({ rows });
  const intl = useIntl();
  const theme = useTheme();
  return (
    <>
      {!readonly && (
        <>
          <CustomBox
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CustomButton
              intlId="bot.config.dataSources.button.add"
              variant="outlined"
              onClick={() => setOpen(true)}
              size="small"
              startIcon={<Add />}
              color="inherit"
            />
          </CustomBox>
          {open && (
            <CustomDialog
              open={open}
              onClose={() => setOpen(false)}
              maxWidth="lg"
              sx={{
                width: '100%',
                zIndex: '9999999 !important',
              }}
              contentChildren={
                <CustomBox>
                  <CustomBox
                    sx={{
                      py: (theme) => theme.spacing(1),
                    }}
                  >
                    <DatasourceSearchBarWithFilter
                      dataSourcesCategories={categories}
                      dataSourcesSyncStatus={syncStatusList}
                      onChangeCategory={(category) =>
                        setCurrentCategory(category)
                      }
                      onChangeInputSearch={(queryString: string) =>
                        setQueryString(queryString)
                      }
                      handleChangeStatus={(status) => setCurrentStatus(status)}
                      canUseFilter={rows.length > 1}
                    />
                  </CustomBox>
                  <CustomBox
                    width={'100%'}
                    sx={{
                      '.MuiTableRow-root:hover': {
                        backgroundColor: theme.palette.customColor.hoverColor,
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <STDataGrid
                      onSelectionModelChange={handleCheckRow}
                      checkboxSelection={true}
                      rows={filteredRows}
                      columns={columns}
                      selectedRows={value || []}
                      disableRowSelectionOnClick={true}
                    />
                  </CustomBox>
                </CustomBox>
              }
              handleClose={() => {
                setOpen(false);
              }}
              title={intl.formatMessage({
                id: 'bot.config.dataSources.dialog.title',
              })}
            />
          )}
        </>
      )}

      {rowsSelected?.length > 0 ? (
        <CustomBox mt={1}>
          <STDataGrid rows={rowsSelected} columns={columnsSelected} />
        </CustomBox>
      ) : (
        <CustomBox mt={1}>
          <AlertText
            id="bot.config.dataSources.noData"
            message={'No data sources added yet'}
            severity="info"
          />
        </CustomBox>
      )}
    </>
  );
};

export default STDataSourcePicker;
