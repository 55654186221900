import * as React from 'react';
import { Grid, useTheme } from '@mui/material';
import ContentEditable from 'react-contenteditable';
import { useMutation } from '@apollo/client';
import { graphql } from '../../../../../../gql';

interface props {
  title?: string | null;
  todoID?: number;
  disabled?: boolean;
}

const UPDATE_TITLE = graphql(`
  mutation updateTodoTitle($updateTodoInput: UpdateTodoInput!) {
    updateTodo(updateTodoInput: $updateTodoInput) {
      id
      title
      description
    }
  }
`);

const EditLabel = ({ todoID, title, disabled }: props) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark' ? true : false;
  const [newTitle, setNewTitle] = React.useState(title as string);
  const [updateTodoTitle] = useMutation(UPDATE_TITLE);
  const debounceTimeoutRef = React.useRef(null as any);
  const contentEditableRef = React.useRef<HTMLDivElement>(null);
  const [isInline, setIsInline] = React.useState(false);
  const handleUpdate = (value: string) => {
    updateTodoTitle({
      variables: {
        updateTodoInput: {
          id: todoID as number,
          name: value,
        },
      },
    });
  };

  const handleChange = (e: any) => {
    setNewTitle(e.target.value);
    clearTimeout(debounceTimeoutRef?.current);
    debounceTimeoutRef.current = setTimeout(() => {
      if (Boolean(e?.target?.value?.trim().length)) {
        handleUpdate(e.target.value);
      }
    }, 500);
    if (contentEditableRef.current) {
      const contentWidth = contentEditableRef.current.offsetWidth;
      if (contentWidth > 200) {
        setIsInline(true); // Switch to inline display
      }
    }
  };

  const handleBlur = (e: any) => {
    clearTimeout(debounceTimeoutRef?.current);
    debounceTimeoutRef.current = setTimeout(() => {
      if (!Boolean(contentEditableRef.current?.innerText?.trim().length)) {
        setNewTitle(newTitle);
      }
    }, 250);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Do nothing on key press for now
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && event.shiftKey) {
      // Do nothing when shift is pressed and enter is pressed
      // This is to allow for new lines
    } else if (event.key === 'Enter') {
      contentEditableRef?.current?.blur();
    }
  };
  React.useEffect(() => {
    setNewTitle(title as string);
  }, [title]);
  const style = {
    lineHeight: '18px',
    marginTop: '5px',
    fontSize: '13px',
    padding: '0px',
    overflow: 'hidden',
    border: 'none',
    color: theme.palette.mode === 'dark' ? 'white' : 'initial',
    transition: 'all 0.1s',
    cursor: 'pointer',
    flex: 1,
    textOverflow: 'ellipsis',
    display: isInline ? 'inline' : 'block',
  };

  return (
    <Grid
      maxWidth="100%"
      container
      sx={{ overflowWrap: 'anywhere' }}
      display="flex"
      flexWrap="wrap"
    >
      <Grid item xs={12} maxWidth="100%">
        <ContentEditable
          html={newTitle as string}
          disabled={disabled}
          innerRef={contentEditableRef}
          onChange={handleChange}
          onKeyUp={onKeyPress}
          onKeyDown={handleKeyDown}
          style={style}
          tagName="p"
          inputMode="text"
          aria-multiline={false}
          onBlur={handleBlur}
          className="content-editable"
        />
      </Grid>
    </Grid>
  );
};
export default EditLabel;
