import React from 'react';
import { IconButton, List, ListItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '../CustomTypography/CustomTypography';
import Box from '../CustomBox/CustomBox';

type File = {
  name: string;
  url: string;
};

type FileListProps = {
  files: File[];
  onDelete: (index: number) => void;
};

const FileList: React.FC<FileListProps> = ({ files, onDelete }) => {
  const deleteFileByIndex = (index: number) => () => {
    onDelete(index);
  };

  return (
    <Box>
      <List>
        {files.map((file, index) => (
          <ListItem
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.grey[200],
              }}
            >
              <a href={file.url} target="_blank" rel="noreferrer">
                {file.name}
              </a>
            </Typography>
            <IconButton onClick={deleteFileByIndex(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default FileList;
