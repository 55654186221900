import { Box, Typography } from '@mui/material';
import UserAvatar from '../../../../common/UserAvatar/UserAvatar';

export interface UserProfilType {
  userProfil: string | null | undefined;
  userEmail?: string;
  lastName?: string;
  userName: string;
  alt?: string;
  sx?: any;
}

const CustomBox = (props: any) => <Box {...props} />;
const CustomTypography = (props: any) => <Typography {...props} />;

const UserProfil = ({
  alt,
  userName,
  userProfil,
  userEmail,
  lastName,
  sx,
}: UserProfilType) => {
  return (
    <CustomBox display={'flex'} alignItems={'center'}>
      <CustomBox mr={1}>
        <UserAvatar
          sx={sx ?? { width: 21, height: 21 }}
          alt={alt}
          userProfil={userProfil}
          lastName={lastName}
          email={userEmail}
        />
      </CustomBox>
      <CustomBox>
        <CustomTypography variant="h6">{userName}</CustomTypography>
        {userEmail && (
          <CustomTypography fontSize={'12px'} variant="body1">
            {userEmail}
          </CustomTypography>
        )}
      </CustomBox>
    </CustomBox>
  );
};

export default UserProfil;
