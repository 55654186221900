import { Box, CircularProgress, IconButton, useTheme } from '@mui/material';
import Prompts from '../../../../../Prompts/Prompts';
import CustomTooltip from '../../../../../common/CustomToolTip/CustomToolTip';
import { FormattedMessage } from 'react-intl';
import StopRecording from '../SvgIcon/StopRecording';
import PapperClipAttache from '../SvgIcon/PapperClipAttache';
import ScreenCastIcon from '../SvgIcon/ScreenCastIcon';
import useGetChatSessionDataSource from '../../../../hooks/useGetChatSessionDataSource';
import React from 'react';
import SpeechToText from '../../../../../common/SpeechToText/SpeechToText';
import CustomDropZone from '../../../../../common/CustomDropZone/CustomDropZone';
import { useContextFormChat } from '../../../../hooks/useContextFormChat';
import CustomIconButton from '../../../../../common/CustomIconButton/CustomIconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SwitchBotAction from './SwitchBotAction';
import { Bot } from '../../../../../../gql/graphql';
import verifyNotNull from '../../../../../../utils/verifyNotNul';
import STMagicPrompt from '../../../../../common/STMagicPrompt/STMagicPrompt';
import config from '../../../../../../config/config';

interface props {
  handleValidePrompts(prompts: string): void;
  isRecording: boolean;
  stopRecording(): void;
  startRecording(): void;
  isSendMessageLoading: boolean;
  onSendForm(e: any): void;
  files: any;
  messageValue: string;
  onMouseEnterDropzone(): void;
  acceptFileInput?: boolean;
  onMouseLeaveDropzone(): void;
  handleTransciptAudio(word: string): void;
  isBotConfigMode?: boolean;
}
const PropsActions = ({
  handleValidePrompts,
  isRecording,
  stopRecording,
  startRecording,
  acceptFileInput,
  isSendMessageLoading,
  onSendForm,
  handleTransciptAudio,
  onMouseLeaveDropzone,
  onMouseEnterDropzone,
  messageValue,
  files,
  isBotConfigMode,
}: props) => {
  const theme = useTheme();

  const { isInifiniteChat, onUploadFile } = useContextFormChat();
  const { data: dataSourceSession } = useGetChatSessionDataSource();
  // Use memo to avoid re-rendering
  const shouldDisplayFileInput = React.useMemo(() => {
    if (isBotConfigMode) {
      return false;
    }
    if (acceptFileInput) {
      return true;
    }
    if (!isInifiniteChat && acceptFileInput) {
      return true;
    }

    return false;
  }, [isInifiniteChat, acceptFileInput, isBotConfigMode]);
  return (
    <Box display={'flex'} alignItems="center" width="100%">
      <Box
        alignItems="center"
        sx={{
          display: 'flex',
        }}
      >
        {dataSourceSession?.getOneTodoChatSession?.defaultBot?.name ? (
          <SwitchBotAction
            defaultBot={
              dataSourceSession?.getOneTodoChatSession?.defaultBot as Bot
            }
          />
        ) : null}
        <Prompts handleValidePrompts={handleValidePrompts} />
      </Box>

      <Box display="flex" alignItems="center" ml="auto">
        <Box
          display={{
            xs: 'none',
            sm: 'none',
            lg: 'block',
            md: 'block',
            xl: 'block',
          }}
        >
          {isRecording ? (
            <Box m="auto">
              <CustomTooltip
                arrow
                title={<FormattedMessage id="chat.form.record.audio.stop" />}
              >
                <IconButton
                  type="button"
                  onClick={stopRecording}
                  aria-label="stop-recording"
                >
                  <StopRecording />
                </IconButton>
              </CustomTooltip>
            </Box>
          ) : !isInifiniteChat && !isBotConfigMode ? (
            <Box m="auto">
              <CustomTooltip
                arrow
                title={<FormattedMessage id="chat.form.record.screen" />}
              >
                <IconButton
                  type="button"
                  onClick={startRecording}
                  aria-label="record-screen"
                >
                  <ScreenCastIcon />
                </IconButton>
              </CustomTooltip>
            </Box>
          ) : null}
        </Box>
        <Box mx={0.3}>
          <STMagicPrompt
            prompt={config.AUTO_MAGIC_PROMPT_PROMPT}
            onRegenerate={(text) => handleValidePrompts(text)}
            textPrompt={messageValue}
          />
        </Box>
        <Box mx={0.3}>
          {/* <RecordForm handleGetAudio={handleTransciptAudio} /> */}
          <SpeechToText />
        </Box>
        {shouldDisplayFileInput ? (
          <CustomDropZone
            onDropFile={onUploadFile}
            multiple={true}
            disabled={false}
          >
            <CustomTooltip
              arrow
              title={<FormattedMessage id="chat.form.attach" />}
            >
              <IconButton
                type="button"
                aria-label="search"
                onMouseEnter={onMouseEnterDropzone}
                onMouseLeave={onMouseLeaveDropzone}
                disabled={Boolean(
                  dataSourceSession?.getOneTodoChatSession?.allDataSources
                    ?.length
                )}
              >
                <PapperClipAttache />
              </IconButton>
            </CustomTooltip>
          </CustomDropZone>
        ) : null}

        <CustomTooltip
          arrow={true}
          title={
            !isSendMessageLoading ? (
              <FormattedMessage id="chat.form.send" />
            ) : (
              <FormattedMessage id="chat.form.send.progress" />
            )
          }
        >
          <CustomIconButton
            onSubmit={onSendForm}
            onClick={onSendForm}
            sx={{
              background: (theme) =>
                verifyNotNull(messageValue) || files.length
                  ? theme.palette.primary.main
                  : theme.palette.grey[200],
              cursor: 'pointer',
              '&:hover': {
                background: theme.palette.customColor.hoverPrimary,
              },
            }}
          >
            {isSendMessageLoading ? (
              <CircularProgress sx={{ color: 'white' }} size={20} />
            ) : (
              <ArrowUpwardIcon sx={{ color: 'white' }} />
            )}
          </CustomIconButton>
        </CustomTooltip>
      </Box>
    </Box>
  );
};
export default PropsActions;
