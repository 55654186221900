import { FC } from 'react';
import { graphql } from '../../../gql';
import { useMutation } from '@apollo/client';
import useDisplayMessage from '../GlobalMessage/useDisplayMessage';
const UPLOAD_GDRIVE_FILE = graphql(`
  mutation uploadGDriveFiles(
    $files: [NewGdriveInput!]!
    $accessToken: String!
  ) {
    uploadGDriveFiles(files: $files, accessToken: $accessToken) {
      id
      name
      size
      type
      url
    }
  }
`);

interface IProps {
  onCompleted?(files: any): void;
}

const useUploadGdriveFile = (props: IProps = {}) => {
  const { displayMessage } = useDisplayMessage();
  const [uploadGDriveFiles, { loading, error }] = useMutation(
    UPLOAD_GDRIVE_FILE,
    {
      update: (cache, { data }) => {
        if (props.onCompleted && data?.uploadGDriveFiles) {
          props.onCompleted(data.uploadGDriveFiles);
        }
      },
      onError: (error) => {
        displayMessage({
          type: 'error',
          message: 'Failed to load files from Google Drive',
        });
      },
    }
  );
  return { uploadGDriveFiles, loading, error };
};

export default useUploadGdriveFile;
