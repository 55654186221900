import { styled } from '@mui/material/styles';

export const StyledScrollBar = styled('div')<{
  clientHeight: number | undefined;
  clientTop: number | undefined;
}>(({ clientHeight, clientTop, theme }) => ({
  height: clientHeight ? `${clientHeight}px` : '0px',
  width: '6px',
  position: 'fixed',
  right: '0',
  top: clientTop ? `${clientTop}px` : '0',
  borderRadius: '6px',
  zIndex: 120,
  [theme.breakpoints.down(1200)]: {
    zIndex: 50,
  },
}));

export const StyledScrollIndicator = styled('div')<{
  indicatorHeight: number;
  indicatorTop: number;
  isDragging: boolean;
}>(({ indicatorHeight, indicatorTop, isDragging, theme }) => ({
  background: theme.palette.customColor.scrollColor,
  position: 'absolute',
  width: '100%',
  height: `${indicatorHeight}px`,
  borderRadius: '6px',
  cursor: 'pointer',
  top: `${indicatorTop}px`,
  transition: isDragging ? 'none' : 'top 0.1s ease-out',
  '&:hover': {
    backgroundColor: theme.palette.customColor.cardBackgroundHover,
  },
}));

export const StyledContentMessage = styled('div')(({ theme }) => ({
  height: '100vh', // Increased height for better scrollbar visibility
  overflowY: 'scroll',
  width: '100%',
  scrollbarWidth: 'none', // Hide scrollbar for Firefox
  overflowX: 'hidden',
}));
